<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Catálogo de áreas de negocio</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord()">Añadir</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload()" />
						</div>
					</div>
					<a-table :columns="columns" :dataSource="businessAreasList" rowKey="id">
						<span slot="active" slot-scope="record">
							<a-tag color="green" v-if="record.active">Activo</a-tag>
							<a-tag color="red" v-else>Inactivo</a-tag>
						</span>
						<span slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record)" />
							<a-button class="ml5" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="onDisableRecord(record.id, record.active)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
		<insertOrUpdateBusinessArea :visible="modal.visible" :title="modal.title" :payload="modal.payload" @closeModal="onCloseModal" />
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import insertOrUpdateBusinessArea from '@/components/insertOrUpdateBusinessArea'

export default {
	name: 'BusinessAreas',
	components: {
		insertOrUpdateBusinessArea,
	},

	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Nombre',
					dataIndex: 'name',
				},
				{
					title: 'Responsable de área',
					dataIndex: 'attendant',
				},
				{
					title: 'Estatus',
					scopedSlots: { customRender: 'active' },
					align: 'center',
				},
			],
			modal: {
				visible: false,
				title: '',
				payload: {},
			},
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('businessAreas', ['businessAreasList']),
		spinnerLoaderLabel() {
			return this.$store.state.businessAreas.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.businessAreas.spinnerLoader
		},

		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
	},
	beforeMount() {
		if (this.isUserAdmin) {
			this.columns.push({
				title: 'Acciones',
				scopedSlots: { customRender: 'action' },
				align: 'right',
			})
		}
	},
	mounted() {
		if (this.businessAreasList.length == 0) {
			this.initModule()
		}
	},

	methods: {
		initModule() {
			this.$store.dispatch('businessAreas/GET')
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.modal = {
				visible: true,
				title: 'Añadir una nueva área',
				payload: {},
			}
		},
		onEditRecord(record) {
			this.modal = {
				visible: true,
				title: 'Editar el área de negocio',
				payload: _.cloneDeep(record),
			}
		},
		onDisableRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					if (status) {
						this.$store.dispatch('businessAreas/DISABLE', id)
					} else {
						this.$store.dispatch('businessAreas/ENABLE', id)
					}
				}
			})
		},
		onCloseModal() {
			this.modal.visible = false
		},
	},
}
</script>